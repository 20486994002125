import { FlowEditorSDK } from '@wix/yoshi-flow-editor';

export async function openSettingsPanel(
  editorSDK: FlowEditorSDK,
  appDefinitionId: string,
) {
  return openDashboardModal(editorSDK, appDefinitionId, 'settings');
}

export async function openBusinessInfoPanel(
  editorSDK: FlowEditorSDK,
  appDefinitionId: string,
) {
  return openDashboardModal(
    editorSDK,
    appDefinitionId,
    'settings/business-info?referralInfo=pay-button',
  );
}

export async function openCurrencyPanel(
  editorSDK: FlowEditorSDK,
  appDefinitionId: string,
) {
  return openDashboardModal(
    editorSDK,
    appDefinitionId,
    'settings/language-and-region?referralInfo=pay-button',
  );
}

export async function openConnectPaymentMethodsPanel(params: {
  editorSDK: FlowEditorSDK;
  appDefinitionId: string;
  startLoadTime: number;
}) {
  return openDashboardModal(
    params.editorSDK,
    params.appDefinitionId,
    `payments?referralInfo=pay-button&startLoadTime=${params.startLoadTime}`,
  );
}

export function openModal(
  editorSDK: FlowEditorSDK,
  appDefinitionId: string,
  url: string,
) {
  return editorSDK.editor.openModalPanel(appDefinitionId, {
    url,
    width: '100%',
    height: '100%',
    closeOtherPanels: true,
  });
}

export function openDashboardModal(
  editorSDK: FlowEditorSDK,
  appDefinitionId: string,
  url: string,
): Promise<void> {
  return editorSDK.editor.openDashboardPanel(appDefinitionId, {
    url,
    closeOtherPanels: true,
  });
}
