import { addController } from './index';
import { connectButton } from './connect';
import { execEditorAction } from './execEditorAction';
import { ComponentRef, FlowEditorSDK } from '@wix/yoshi-flow-editor';
import { buttonRole } from './constants';
import { PageRef } from '@wix/editor-platform-sdk-types';
import { HandleError } from '../types';

export const addControllerAndConnectButton = async (
  editorSDK: FlowEditorSDK,
  appDefinitionId: string,
  pageRef: PageRef,
  handleError: HandleError,
): Promise<ComponentRef> => {
  try {
    return await execEditorAction({
      editorSDK,
      appDefinitionId,
      action: () =>
        editorSDK.document.transactions.runAndWaitForApproval(
          appDefinitionId,
          async (): Promise<ComponentRef> => {
            const controllerRef = await addController(
              editorSDK,
              appDefinitionId,
              pageRef,
            );
            await connectButton(
              editorSDK,
              controllerRef,
              buttonRole,
              appDefinitionId,
            );
            return controllerRef;
          },
        ),
    });
  } catch (e) {
    if (
      await editorSDK.document.transactions.isConflictError(appDefinitionId, e)
    ) {
      handleError(e, 'Error in runAndWaitForApproval method (conflict).');
    } else {
      handleError(e, 'Error in adding controller and connecting a button.');
    }
  }

  throw new Error('Error at addControllerAndConnectButton');
};
