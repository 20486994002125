import { ComponentRef } from '@wix/yoshi-flow-editor';
import { EditorInteractionName } from '../enums/Interaction';
import { EditorAppContext } from '../types';
import { addControllerAndConnectButton } from '../utils/addControllerAndConnectButton';
import { createProduct } from '../utils/api';
import { createDefaultProduct } from '../utils/constants';
import { overrideConnectionConfig } from '../utils/overrideConnectionConfig';

export const doFirstInstallAppMarket = async ({
  editorSDK,
  appDefinitionId,
  httpClient,
  fedops,
  translate,
  handleError,
}: EditorAppContext) => {
  fedops.interactionStarted(EditorInteractionName.FIRST_INSTALL_APP_MARKET);
  const [currency, pageRef, metaSiteId] = await Promise.all([
    editorSDK.document.info.getCurrency(appDefinitionId),
    editorSDK.pages.getCurrent(''),
    editorSDK.document.info.getMetaSiteId(''),
  ]);
  const controllerRef = await addControllerAndConnectButton(
    editorSDK,
    appDefinitionId,
    pageRef,
    handleError,
  );
  try {
    const instance = await editorSDK.info.getAppInstance(appDefinitionId);
    const resp = await createProduct(
      httpClient,
      instance,
      createDefaultProduct(currency, translate('item.defaultName')),
    );
    const productId = resp.productId;
    await overrideConnectionConfig(
      editorSDK,
      appDefinitionId,
      controllerRef,
      { productId, metaSiteId },
      handleError,
    );
  } catch (e) {
    handleError(e, 'Error while creating a product.');
  }

  await editorSDK.document.save();
  fedops.interactionEnded(EditorInteractionName.FIRST_INSTALL_APP_MARKET);
};

export const doFirstInstallAddPanel = async (
  componentRef: ComponentRef,
  {
    editorSDK,
    appDefinitionId,
    httpClient,
    fedops,
    translate,
    handleError,
  }: EditorAppContext,
) => {
  fedops.interactionStarted(EditorInteractionName.FIRST_INSTALL_ADD_PANEL);
  try {
    const [currency, metaSiteId, instance] = await Promise.all([
      editorSDK.document.info.getCurrency(appDefinitionId),
      editorSDK.document.info.getMetaSiteId(''),
      editorSDK.info.getAppInstance(appDefinitionId),
    ]);

    const { productId } = await createProduct(
      httpClient,
      instance,
      createDefaultProduct(currency, translate('item.defaultName')),
    );

    await overrideConnectionConfig(
      editorSDK,
      appDefinitionId,
      componentRef,
      { productId, metaSiteId },
      handleError,
    );
  } catch (e) {
    handleError(e, 'Error while creating a product.');
  }

  await editorSDK.document.save();
  fedops.interactionEnded(EditorInteractionName.FIRST_INSTALL_ADD_PANEL);
};
