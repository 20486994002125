import { ComponentRef, FlowEditorSDK } from '@wix/yoshi-flow-editor';
import { HandleError } from '../types';
import { execEditorAction } from './execEditorAction';

export const overrideConnectionConfig = async (
  editorSDK: FlowEditorSDK,
  appDefinitionId: string,
  controllerRef: ComponentRef,
  connectionConfig: any,
  handleError: HandleError,
) => {
  try {
    const { config } = await editorSDK.document.controllers.getData(
      appDefinitionId,
      { controllerRef },
    );
    await execEditorAction({
      editorSDK,
      appDefinitionId,
      action: () =>
        editorSDK.document.transactions.runAndWaitForApproval(
          appDefinitionId,
          () => {
            return editorSDK.document.controllers.saveConfiguration(
              appDefinitionId,
              {
                controllerRef,
                config: {
                  ...config,
                  ...connectionConfig,
                },
              },
            );
          },
        ),
    });
  } catch (e) {
    if (
      await editorSDK.document.transactions.isConflictError(appDefinitionId, e)
    ) {
      handleError(e, 'Error in runAndWaitForApproval method (conflict).');
    } else {
      handleError(e, 'Error in saving configuration.');
    }
  }
};
