import { payButtonClickToOpenPaymentSettings } from '@wix/bi-logger-pay-button/v2';
import { EditorEvent } from '../enums/editorEvents';
import { updateProductCurrencyFromSettings } from '../utils/api';
import { panelHelpId } from '../utils/constants';
import { overrideConnectionConfig } from '../utils/overrideConnectionConfig';
import {
  createSettingsPanelConfig,
  getButtonText,
  updateButtonLabel,
} from '../utils';
import { uniq } from 'lodash';
import {
  openBusinessInfoPanel,
  openConnectPaymentMethodsPanel,
  openCurrencyPanel,
  openSettingsPanel,
} from '../utils/panels';
import { getProductIdsList } from '../utils/getProductIdsList';
import { showSSLEnableNotification } from '../utils/showSSLNotification';
import { redefineButtonProductId } from '../utils/duplicateCase';
import { createSentryClient } from '../utils/sentryClientFactory';
import { EditorAppContext, EditorPlatformApp } from '../types';
import {
  doFirstInstallAddPanel,
  doFirstInstallAppMarket,
} from './doFirstInstall';
import { getAppManifest } from './getAppManifest';
import { ComponentRef } from '@wix/yoshi-flow-editor';
import { EditorInteractionName } from '../enums/Interaction';
import { getBiLoggerParams } from '../utils/getBiLoggerParams';
import { getBiLogger } from '../utils/biLogger';
import { PlatformEvent } from '@wix/platform-editor-sdk';

export function createPlatformApp(): EditorPlatformApp {
  let appContext: EditorAppContext;

  const handleSdkNotInitialized = () => {
    appContext.handleError(new Error('EditorSDK was not initialized.'));
  };

  return {
    async editorReady(
      editorSDK,
      appDefinitionId,
      { firstInstall, origin, monitoring, initialAppData, essentials },
      flowAPI,
    ) {
      const { experiments, fedops, environment, translations } = flowAPI;

      const sentry = createSentryClient(monitoring, {
        firstInstall,
        metaSiteId: initialAppData.metaSiteId,
        isEditorX: environment.isEditorX,
      });

      appContext = {
        translate: flowAPI.translations.t.bind(translations),
        editorSDK,
        appDefinitionId,
        fedops,
        experiments,
        sentry,
        httpClient: flowAPI.httpClient,
        bi: getBiLogger({}, essentials.biLoggerFactory()),
        isEditorX: environment.isEditorX,
        handleError: (error, errorMessage) => {
          console.error(errorMessage || error);
          sentry.captureException(error);
        },
      };

      try {
        await flowAPI.translations.init();

        const isSSLEnable = await editorSDK.info.isHttpsEnabled('');
        if (!isSSLEnable) {
          showSSLEnableNotification(appContext);
        }

        if (firstInstall && origin?.info?.type === 'APP_MARKET') {
          doFirstInstallAppMarket(appContext);
        }
      } catch (error) {
        appContext.handleError(error);
      }
    },
    async getAppManifest({ appManifestBuilder }) {
      return getAppManifest(appManifestBuilder, appContext);
    },
    async onEvent(event: PlatformEvent, editorSDK) {
      const { eventPayload = {}, eventType } = event;
      const componentRef = eventPayload.componentRef || eventPayload.compRef;
      const { appDefinitionId, httpClient, fedops, handleError } = appContext;

      try {
        switch (eventPayload.id) {
          case EditorEvent.ConnectPaymentMethods: {
            fedops.interactionStarted(
              EditorInteractionName.OPEN_CONNECT_PAYMENT_METHODS_MODAL,
            );

            if (!editorSDK) {
              handleSdkNotInitialized();
              return;
            }

            openConnectPaymentMethodsPanel({
              editorSDK,
              appDefinitionId,
              startLoadTime: Date.now(),
            });
            break;
          }

          case EditorEvent.OpenPaymentSettings: {
            fedops.interactionStarted(
              EditorInteractionName.PAY_BUTTON_OPEN_SETTINGS,
            );

            if (!editorSDK) {
              handleSdkNotInitialized();
              return;
            }

            const params = await getBiLoggerParams(
              appContext.editorSDK,
              componentRef.id,
            );
            appContext.bi.report(payButtonClickToOpenPaymentSettings(params));
            let {
              config: { productId: savedProductId },
            } = await editorSDK.document.controllers.getData(appDefinitionId, {
              controllerRef: componentRef,
            });
            savedProductId = await redefineButtonProductId({
              appContext,
              controllerRef: componentRef,
              savedProductId,
            });
            const instance = await editorSDK.info.getAppInstance(
              appDefinitionId,
            );
            await updateProductCurrencyFromSettings(
              httpClient,
              savedProductId,
              instance,
            );

            const { label: buttonLabel } = await getButtonText(
              editorSDK,
              appDefinitionId,
              componentRef,
            );
            const settings = createSettingsPanelConfig({
              appContext,
              settingsTitle: appContext.translate('settingsPanel.title'),
              startLoadTime: Date.now(),
              buttonLabel,
              componentRef,
              productId: savedProductId,
              helpId: panelHelpId,
              instance,
            });
            editorSDK.editor.openComponentPanel('settings-panel', settings);
            break;
          }

          default: {
            break;
          }
        }
      } catch (e) {
        handleError(e);
      }

      try {
        if (eventType === 'componentAddedToStage') {
          doFirstInstallAddPanel(componentRef, appContext);
        }

        if (eventType === 'componentDeleted') {
          await editorSDK.document.save();
        }
      } catch (error) {
        handleError(
          error,
          `[createEditorPlatform.OnEvent fn] eventType=${eventType}`,
        );
      }
    },
    exports: {
      overrideConnectionConfig: (
        controllerRef: ComponentRef,
        connectionConfig,
      ) =>
        overrideConnectionConfig(
          appContext.editorSDK,
          appContext.appDefinitionId,
          controllerRef,
          connectionConfig,
          appContext.handleError,
        ),
      updateButtonLabel: (componentRef: ComponentRef, label: string) =>
        updateButtonLabel(
          appContext.editorSDK,
          appContext.appDefinitionId,
          componentRef,
          label,
          appContext.handleError,
        ),
      // TODO: remove after migration to openCurrencyPanel && openBusinessInfoPanel in pb-settings
      openSettingsPanel: async (appDefId: string) => {
        try {
          // https://wix.slack.com/archives/C4KPAQ33K/p1542103124049300
          await openSettingsPanel(appContext.editorSDK, appDefId);
          const productIds = await getProductIdsList(appContext.editorSDK);
          const instance = await appContext.editorSDK.info.getAppInstance(
            appContext.appDefinitionId,
          );
          uniq(productIds).forEach((id) =>
            updateProductCurrencyFromSettings(
              appContext.httpClient,
              instance,
              id,
            ),
          );
        } catch (e) {
          appContext.handleError(e);
        }
      },
      openCurrencyPanel: async (appDefId: string) => {
        try {
          await openCurrencyPanel(appContext.editorSDK, appDefId);
          // TODO: revise update updateProductCurrencyFromSettings logic
          const productIds = await getProductIdsList(appContext.editorSDK);
          const instance = await appContext.editorSDK.info.getAppInstance(
            appContext.appDefinitionId,
          );
          uniq(productIds).forEach((id) =>
            updateProductCurrencyFromSettings(
              appContext.httpClient,
              instance,
              id,
            ),
          );
        } catch (e) {
          appContext.handleError(e);
        }
      },
      openBusinessInfoPanel: async (appDefId: string) => {
        try {
          await openBusinessInfoPanel(appContext.editorSDK, appDefId);
        } catch (e) {
          appContext.handleError(e);
        }
      },
    },
  };
}
