import { AppManifestBuilder } from '@wix/yoshi-flow-editor';
import { EditorEvent } from '../enums/editorEvents';
import { Experiment } from '../enums/Experiment';
import { EditorAppContext } from '../types';
import { getPremiumStatus } from '../utils/api';

export const getAppManifest = async (
  appManifestBuilder: AppManifestBuilder,
  {
    translate,
    experiments,
    httpClient,
    editorSDK,
    appDefinitionId,
  }: EditorAppContext,
) => {
  const instance = await editorSDK.info.getAppInstance(appDefinitionId);
  const isPremium = await getPremiumStatus(httpClient, instance);

  let showUpgradeButton = false;
  if (!isPremium) {
    showUpgradeButton = experiments.enabled(Experiment.PayButtonCrownButton);
  }

  return appManifestBuilder
    .withJsonManifest({
      controllersStageData: {
        WixPay: {
          default: {
            gfpp: {
              desktop: {
                mainAction1: {
                  actionId: EditorEvent.OpenPaymentSettings,
                  label: translate('buttonGFPP.mainAction1'),
                },
                mainAction2: {
                  actionId: EditorEvent.ConnectPaymentMethods,
                  label: translate('buttonGFPP.mainAction2'),
                },
                iconButtons: {
                  link: 'HIDE',
                  ...(showUpgradeButton
                    ? {
                        upgrade: 'SITE_UPGRADE',
                      }
                    : {}),
                },
                helpId: 'ff0c64a8-98f0-470a-9135-622f1504de83',
              },
            },
            behavior: {
              rotatable: false,
            },
          },
        },
      },
    })
    .build();
};
