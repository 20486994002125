import { PageRef } from '@wix/editor-platform-sdk-types';
import { ComponentRef, FlowEditorSDK } from '@wix/yoshi-flow-editor';
import { EditorAppContext, HandleError } from '../types';
import { execEditorAction } from './execEditorAction';
import { buttonStructure } from './structures/button';
import { getControllerStructure } from './structures/controller';

export async function getChildren(
  editorSDK: FlowEditorSDK,
  appDefinitionId: string,
  componentRef: ComponentRef,
): Promise<ComponentRef[]> {
  return editorSDK.components.getChildren(appDefinitionId, {
    componentRef,
  });
}

export async function getButtonText(
  editorSDK: FlowEditorSDK,
  appDefinitionId: string,
  componentRef: ComponentRef,
): Promise<{ label: string }> {
  const [button] = await getChildren(editorSDK, appDefinitionId, componentRef);
  return (await editorSDK.document.components.data.get(appDefinitionId, {
    componentRef: button,
  })) as Promise<{ label: string }>;
}

export async function updateButtonLabel(
  editorSDK: FlowEditorSDK,
  appDefinitionId: string,
  componentRef: ComponentRef,
  label: string,
  handleError: HandleError,
) {
  try {
    const [button] = await getChildren(
      editorSDK,
      appDefinitionId,
      componentRef,
    );
    await execEditorAction({
      editorSDK,
      appDefinitionId,
      action: () =>
        editorSDK.document.transactions.runAndWaitForApproval(
          appDefinitionId,
          () => {
            return editorSDK.document.components.data.update(appDefinitionId, {
              componentRef: button,
              data: { label },
            });
          },
        ),
    });
  } catch (e) {
    if (
      await editorSDK.document.transactions.isConflictError(appDefinitionId, e)
    ) {
      handleError(e, 'Error in runAndWaitForApproval method (conflict).');
    } else {
      handleError(e, 'Error in updating button label.');
    }
  }
}

export const createSettingsPanelConfig = ({
  instance,
  helpId,
  componentRef,
  productId,
  buttonLabel,
  settingsTitle,
  startLoadTime,
  appContext,
}: {
  instance: string;
  helpId: string;
  componentRef: ComponentRef;
  productId: string;
  buttonLabel: string;
  settingsTitle: string;
  startLoadTime: number;
  appContext: EditorAppContext;
}) => {
  const appDefinitionId = appContext.appDefinitionId;

  return {
    componentRef,
    title: settingsTitle,
    width: 288,
    height: 546,
    helpId,
    url: `https://manage.wix.com/pay-button-settings?instance=${instance}`,
    initialData: {
      productId,
      buttonLabel,
      appDefinitionId,
      componentRef,
      startLoadTime,
    },
  };
};

export async function addController(
  editorSDK: FlowEditorSDK,
  appDefinitionId: string,
  pageRef: PageRef,
) {
  return editorSDK.components.add(appDefinitionId, {
    pageRef,
    componentDefinition: getControllerStructure(
      appDefinitionId,
      buttonStructure,
    ),
  });
}
