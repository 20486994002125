import { ComponentRef, FlowEditorSDK } from '@wix/yoshi-flow-editor';
import { getChildren } from './index';

export const connect = ({
  editorSDK,
  controllerRef,
  connectToRef,
  role,
  appDefinitionId = 'token',
}: {
  editorSDK: FlowEditorSDK;
  controllerRef: ComponentRef;
  connectToRef: ComponentRef;
  role: string;
  appDefinitionId: string;
}) => {
  return editorSDK.controllers.connect(appDefinitionId, {
    connectToRef,
    controllerRef,
    role,
    isPrimary: true,
  });
};
export async function connectButton(
  editorSDK: FlowEditorSDK,
  controllerRef: ComponentRef,
  buttonRole: string,
  appDefinitionId: string,
) {
  const [button] = await getChildren(editorSDK, appDefinitionId, controllerRef);
  return connect({
    editorSDK,
    controllerRef,
    connectToRef: button,
    role: buttonRole,
    appDefinitionId,
  });
}
