import { EditorAppContext } from '../types';
import { openModal } from './panels';

export const showSSLEnableNotification = async ({
  editorSDK,
  appDefinitionId,
  translate,
}: EditorAppContext) => {
  const metaSiteId = await editorSDK.document.info.getMetaSiteId('');
  editorSDK.editor
    .showNotification(appDefinitionId, {
      message: translate('notificationSSL.title'),
      type: 'warning',
      link: { caption: translate('notificationSSL.link') },
    })
    .then((isClickedOnLink: boolean) => {
      if (isClickedOnLink) {
        openModal(
          editorSDK,
          appDefinitionId,
          `https://manage.wix.com/dashboard/${metaSiteId}/manage-website/ssl`,
        );
      }
    });
};
