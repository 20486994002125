import { FlowEditorSDK } from '@wix/yoshi-flow-editor';

export const getBiLoggerParams = async (
  editorSDK: FlowEditorSDK,
  component_ref_id: string,
) => {
  const [app_id, msid, userGuid] = await Promise.all([
    editorSDK.document.info.getAppDefinitionId(''),
    editorSDK.document.info.getMetaSiteId(''),
    editorSDK.document.info.getUserId(''),
  ]);
  return { app_id, component_ref_id, msid, userGuid };
};
